<template>
  <NuxtLink :to="urlFilter" :class="customClass" :target="urlTarget">
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
  const props = defineProps({
    path: String,
    customClass: String
  });

  const urlFilter = computed(()  =>  {
    if (props.path !== undefined) {
      const runtimeConfig = useRuntimeConfig();
      const backendUrl = runtimeConfig.public.backendHost;

      const cleanUrl = props.path.replace('%5B0%5D','[]');  //#CURIEPROD-973 : GraphQl send [0] instead of [] when BO URL is for example : /actualites?fieldDomainNews[]=270&page=1

      return cleanUrl.replace(backendUrl, '');
    }
    else {
      return "";
    }
  });

  const urlTarget = computed(()  =>  {
    if (urlFilter.value.startsWith("http://") || urlFilter.value.startsWith("https://")) {
      return "_blank";
    }
    else {
      return "_self";
    }
  });
</script>
