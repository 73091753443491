import { default as _91_46_46_46slug_93IZH1V0H3NPMeta } from "/home/curie-frontend/www/releases/20241010084626/pages/[...slug].vue?macro=true";
import { default as _91id_93d05fXVLBx2Meta } from "/home/curie-frontend/www/releases/20241010084626/pages/apply/[id].vue?macro=true";
import { default as homeMQP0CTrkCBMeta } from "/home/curie-frontend/www/releases/20241010084626/pages/home.vue?macro=true";
import { default as searchROwUbPXeUGMeta } from "/home/curie-frontend/www/releases/20241010084626/pages/search.vue?macro=true";
import { default as spontaneous_45applicationOwAooeJyUSMeta } from "/home/curie-frontend/www/releases/20241010084626/pages/spontaneous-application.vue?macro=true";
import { default as component_45stubkDnwCQqqnhMeta } from "/home/curie-frontend/www/releases/20241010084626/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubkDnwCQqqnh } from "/home/curie-frontend/www/releases/20241010084626/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___fr",
    path: "/:slug(.*)*",
    component: () => import("/home/curie-frontend/www/releases/20241010084626/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/home/curie-frontend/www/releases/20241010084626/pages/[...slug].vue")
  },
  {
    name: "apply-id___fr",
    path: "/apply/:id()",
    component: () => import("/home/curie-frontend/www/releases/20241010084626/pages/apply/[id].vue")
  },
  {
    name: "apply-id___en",
    path: "/apply/:id()",
    component: () => import("/home/curie-frontend/www/releases/20241010084626/pages/apply/[id].vue")
  },
  {
    name: "home___fr",
    path: "/home",
    component: () => import("/home/curie-frontend/www/releases/20241010084626/pages/home.vue")
  },
  {
    name: "home___en",
    path: "/home",
    component: () => import("/home/curie-frontend/www/releases/20241010084626/pages/home.vue")
  },
  {
    name: "search___fr",
    path: "/search",
    component: () => import("/home/curie-frontend/www/releases/20241010084626/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/search",
    component: () => import("/home/curie-frontend/www/releases/20241010084626/pages/search.vue")
  },
  {
    name: "spontaneous-application___fr",
    path: "/spontaneous-application",
    component: () => import("/home/curie-frontend/www/releases/20241010084626/pages/spontaneous-application.vue")
  },
  {
    name: "spontaneous-application___en",
    path: "/spontaneous-application",
    component: () => import("/home/curie-frontend/www/releases/20241010084626/pages/spontaneous-application.vue")
  },
  {
    name: "spontaneous-application",
    path: "/spontaneous-application",
    component: () => import("/home/curie-frontend/www/releases/20241010084626/pages/spontaneous-application.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/home/curie-frontend/www/releases/20241010084626/pages/search.vue")
  },
  {
    name: "230",
    path: "/",
    component: () => import("/home/curie-frontend/www/releases/20241010084626/pages/home.vue")
  },
  {
    name: component_45stubkDnwCQqqnhMeta?.name,
    path: "/redirection-simple-origine",
    component: component_45stubkDnwCQqqnh
  },
  {
    name: component_45stubkDnwCQqqnhMeta?.name,
    path: "/redirection-wildcard-origine/*",
    component: component_45stubkDnwCQqqnh
  },
  {
    name: component_45stubkDnwCQqqnhMeta?.name,
    path: "/redirection-wildcard-prefix-origine/*/suffixe",
    component: component_45stubkDnwCQqqnh
  }
]