<template>
  <UiCustomLink :path="url" :class="buttonClass">
    <nuxt-icon
      v-if="withIcon"
      :name="iconName"
      :class="iconClass"
      filled
      class="mr-2"
    />
    {{ buttonLabel }}
  </UiCustomLink>
</template>

<script>
export default {
  name: "Button",
  props: {
    url: {
      type: String,
      //default: "/",
    },
    buttonLabel: String,
    size: {
      type: String,
      default: "medium",
      validator: (value) => ["small", "medium", "large"].includes(value),
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: "arrowRight",
    },
    iconClass: {
      type: String,
      default: "text-regular",
    },
    variant: {
      type: String,
      default: "primary",
      validator: (value) =>
        ["primary", "secondary", "secondaryAlt"].includes(value),
    },
  },
  computed: {
    buttonClass() {
      return `cursor-pointer inline-block text-lg leading-none font-bold font-raleway rounded py-4 px-6 ${this.getVariantClass()} ${this.getSizeClass()}`;
    },
  },
  methods: {
    getSizeClass() {
      switch (this.size) {
        case "small":
          return "w-8 h-4";
        case "medium":
          return "";
        case "large":
          return "";
        default:
          return "";
      }
    },
    getVariantClass() {
      switch (this.variant) {
        case "primary":
          return "bg-orangeCurie hover:bg-orangeHover text-white";
        case "secondary":
          return "bg-white border border-grisClair  text-black  hover:border-1-1/2 hover:border-grisFont";
        case "secondaryAlt":
          return "bg-white border border-0  text-black  hover:border-0";
        default:
          return "";
      }
    },
  },
};
</script>
