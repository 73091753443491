import { defineNuxtPlugin } from '#app'
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client/core";
import { NuxtApollo } from "#apollo";
//import {getRedisConnection} from "./utils/redisConnection"

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig();
  if(!process.client){
    const { getRedisConnection } = await import('./utils/redisConnection');
    const redisConnection = getRedisConnection(config.public.drupalGraphql.queueOptions.redis);
    nuxtApp.payload.drupalRoutes = await redisConnection.getItem("routes");
    await redisConnection.dispose();
  }

  const fetchPolicy = "no-cache";
  const graphqlClient = new ApolloClient({
    // Provide required constructor fields
    link: new HttpLink({
      uri: NuxtApollo.clients.default.httpEndpoint,
    }),
    cache: new InMemoryCache({
      addTypename: false,
    }),    // Provide some optional constructor fields
    name: "x-graphql-client",
    connectToDevTools: true,
    assumeImmutableResults: true,
    queryDeduplication: false,    defaultOptions: {
      watchQuery: { fetchPolicy },
      query: { fetchPolicy },
    },
  });
  return {
    // Using provide here allows Nuxt3 to generate typings
    provide: {
      graphqlClient
    },
  };
})