<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <div class="hidden">Monitoring Tangkoko Tag</div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig();

useHead({
  link: [
    { rel: "preconnect", href: "https://fonts.googleapis.com" },
    { rel: "preconnect", href: "www.googletagmanager.com" },
    { rel: "preconnect", href: "www.gstatic.com" },
    { rel: "preconnect", href: "consent.cookiebot.com" },
    { rel: "preconnect", href: config.public.cdn },
    { rel: "stylesheet", href: "https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap" },
    { rel: "stylesheet", href: "https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap" }
  ],
});
</script>
