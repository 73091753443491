import { VueReCaptcha } from 'vue-recaptcha-v3';

export default defineNuxtPlugin(async (nuxtApp) => {
    const recaptchaRoutes = {
        bundles:[
            "contact",
            "job_offer",
            "person"
        ],
        names: [
            nuxtApp.$config.public.spontaneousApplicationUrl
        ]
    };
    const router = useRouter();
    watch(()=>router.currentRoute.value,
    (route)=>{
        const drupalRoute = nuxtApp.payload.drupalRoutes[route.path];
        console.log("______________________________________________");
        console.log(drupalRoute);
        if(recaptchaRoutes.names.includes( route.name ) || (drupalRoute && recaptchaRoutes.bundles.includes( drupalRoute.meta.bundle ))){
            nuxtApp.vueApp.use(VueReCaptcha, {
                siteKey: nuxtApp.$config.public.rechaptcha_site_key,
                loaderOptions: {
                    autoHideBadge: false,
                    explicitRenderParameters: {
                        badge: 'bottomright',
                    },
                },
            });
        }
    });
});